import React from "react"
import { Link } from "gatsby"
import Image from "./image";

import "./layout.css";


const BlogLayout = ({ location, title, children }) => {
  return (
    <div>
      <header className="mx-auto p-2 pt-6 mb-10 flex flex-wrap bg-gray-200">
        <div className="max-w-xl lg:max-w-2xl mx-auto w-full text-center">
          <div className="h-16 w-16 rounded-full overflow-hidden m-auto">
            <Image className="object-cover object-top" />
          </div>
        </div>
        <h1 className="max-w-xl lg:max-w-2xl mx-auto w-full text-2xl text-center m-auto mt-2">
          <Link to={`/blog`} className="ml-2">
            {title}
          </Link>
        </h1>
        <p className="max-w-xl lg:max-w-2xl mx-auto w-full body-copy px-10 pt-6 mb-2 text-center text-sm">
          Indie maker, solopreneur, travel enthusiast, and aspiring writer :)
        </p>
        <div className="max-w-xl lg:max-w-2xl mx-auto w-full text-xs text-center my-8">
          <Link to={`/`} className="mx-2 bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">home</Link>
          <Link to={`/blog`} className="mx-2 bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">all posts</Link>
          <Link to={`/about`} className="mx-2 bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">about</Link>
        </div>
      </header>
      <main className="max-w-xl lg:max-w-2xl mx-auto mx-auto px-8">{children}</main>
      <footer className="text-center text-gray-800 bg-gray-100 px-4 py-8">
        <small>Pradeep CE | Copyright &copy; {new Date().getFullYear()} | pradeepcep@gmail.com | <span title="Site design last updated at probably some insane hour on 05 Jul 2020">v2.0</span></small>
      </footer>
    </div>
  )
}

export default BlogLayout
