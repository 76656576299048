import React from "react"
import { Link, graphql } from "gatsby"

import BlogLayout from "../components/blog-layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.blogTitle || data.site.siteMetadata.title
  const { previous, next } = pageContext
  const standAlonePage = post.frontmatter.displayFooterNav === "no";

  return (
    <BlogLayout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article class="blog-article-detail">
        <header>
          <h1>
            {post.frontmatter.title}
          </h1>
          {post.frontmatter.showTimestamp !== "no" && (<p>
            {post.frontmatter.date}
          </p>
          )}
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr />
        <footer>
          <p>Liked this page? Tweet at <a href="https://twitter.com/pradeepcep" target="_blank" rel="noreferrer">@pradeepcep</a> and let me know!</p>
        </footer>
      </article>

      <nav>
        <ul className="flex flex-wrap m-3 p-0">
          <li className="w-full">
            {previous && !standAlonePage && (
              <div className="mt-5 mb-2">
                <Link to={previous.fields.slug} rel="prev" className="blogpost-bottom-link bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
                  ← Previous: <b>{previous.frontmatter.title}</b>
                </Link>
              </div>
            )}
          </li>
          <li className="w-full">
            {next && !standAlonePage && (
              <div className="mt-2 mb-5">
                <Link to={next.fields.slug} rel="next" className="blogpost-bottom-link bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
                  → Next: <b>{next.frontmatter.title}</b>
                </Link>
              </div>
            )}
          </li>
        </ul>
      </nav>

      <p className="text-gray-600">
        <small>The views and opinions expressed on this site are my own. They do not reflect the views or policies of any employer or client, past or present.</small>
      </p>

    </BlogLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        displayFooterNav
        showTimestamp
      }
    }
  }
`
